<template>
  <section id="tiss-guides-table">
    <b-table-simple class="table" borderless striped>
      <thead>
        <tr>
          <th v-can="'FatSusLotAEnv'">
            <Check :value="allChecked" @change="!allChecked ? selectAll() : unSelectAll()" />
          </th>
          <th v-for="(header, index) in headers" :key="index">
            <Ellipsis>{{ header }}</Ellipsis>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="hide"></tr>
        <tr v-for="tissGuide in tissGuides" :key="tissGuide.id">
          <td class="check no-print">
            <Check
              :value="!!selectedTissGuides.includes(tissGuide.id)"
              @change="checked => handleSelectRow(checked, tissGuide)"
            />
          </td>
          <td class="patient">
            {{ tissGuide.patient.name }}<br />
            {{ tissGuide.provider_guide }}
          </td>
          <td class="items">
            <span>{{ normalizeNumber(tissGuide.items_count) }}</span>
            <b-button @click="showItems(tissGuide)" variant="link">
              <Eye class="icon-eye" />
            </b-button>
          </td>
          <td class="situation">
            <SelectItemSituation 
              v-model="tissGuide.situation"
              @change="situation => onChangeSituation(tissGuide, situation)"
            />
          </td>
          <td class="column-default">
            {{ parseDate(tissGuide.updated_at) }}
          </td>
          <td class="column-default">
            {{  parseNumberToMoney(tissGuide.items_total) }}
          </td>
          <td class="receive">
            <span v-if="tissGuide.situation === TissGuide.GUIDE_SITUATION_PARTIALLY_PAID">
              <router-link :to="{ name: 'tiss-manage-guide-items', params: { guideId: tissGuide.id }}">
                <b-button variant="link" class="disabled-value">
                  {{ tissGuide.items_paid > 0 ? parseNumberToMoney(tissGuide.items_paid) : 'Inserir valor' }}
                </b-button>
              </router-link>
            </span>
            <span class="disabled-value" v-else>{{ parseNumberToMoney(tissGuide.items_paid) }}</span>
          </td>
          <td class="column-default">
            <div class="disabled-value" >{{ parseNumberToMoney(tissGuide.items_glossed) }}</div>
          </td>
          <td class="no-print">
            <b-button variant="outline-primary" class="edit-observation" @click="showObservationModal(tissGuide)">
              <Icon :tooltip="tissGuide.observations">
                <Comment class="icon pointer" :class="{ inactive: !tissGuide.observations }" />
              </Icon>
            </b-button>
          </td>
        </tr>
      </tbody>
    </b-table-simple>

    <div class="selected-guides" v-if="selectedTissGuides.length">
      <p>
        Qtde de guias selecionadas:
        <span>
          <strong>{{ selectedTissGuides.length }}</strong> guia(s)
        </span>
      </p>
      <b-button variant="link" @click="unSelectAll">
        Cancelar seleção
      </b-button>
    </div>
    <NoFilterResults
      v-if="!hasResults"
      message="Os filtros aplicados não geraram nenhum resultado."
    />
  </section>
</template>
<script>
import { normalizeNumber, onChangeSituation } from '@/modules/tiss/manageGuides/utils/guideHelper'
import { TissGuide, guideStatusOptions } from '@/modules/tiss/manageGuides/utils/statuses'
import { parseNumberToMoney } from '@/utils/moneyHelper'

export default {
  name: 'guides-table',
  components: {
    Icon: () => import('@/components/General/Icon'),
    Check: () => import('@/components/General/Check'),
    Comment: () => import('@/assets/icons/Comment-lines.svg'),
    Ellipsis: () => import('@/components/General/Ellipsis.vue'),
    Eye: () => import('@/assets/icons/eye.svg'),
    SelectItemSituation: () => import('@/modules/tiss/manageGuides/components/SelectItemSituation'),
    NoFilterResults: () => import('@/components/General/noFilterResults')
  },
  props: {
    tissGuides: Array,
    selectedTissGuides: Array,
    handleSelectRow: Function,
    selectAll: Function,
    unSelectAll: Function
  },
  data() {
    return {
      TissGuide,
      guideStatusOptions,
      selected: [],
      headers: [
        'Paciente/Nº da guia',
        'Itens',
        'Situação',
        'Atualização',
        'Valor total',
        'A receber',
        'Glosado',
        'Obs'
      ],
    }
  },
  computed: {
    allChecked() {
      return this.tissGuides.length > 0 && this.selectedTissGuides.length === this.tissGuides.length
    },
    hasResults() {
      return this?.tissGuides?.length > 0
    },
    getRows() {
      return this.batches && 'rows' in this.batches ? this.batches.rows : null
    }
  },
  methods: {
    normalizeNumber,
    parseNumberToMoney,
    onChangeSituation,
    parseDate(date) {
      return this.moment(date).format('DD/MM/YYYY')
    },
    showItems(tissGuide) {
      this.$router.push({
        name: 'tiss-manage-guide-items',
        params: { guideId: tissGuide.id }
      })
    },
    async showObservationModal(tissGuide) {
      const res = await this.$swal({
        title: 'Observações',
        input: 'textarea',
        inputValue: tissGuide.observations || '',
        reverseButtons: true,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#305BF2',
        cancelButtonText: 'Cancelar',
        inputAttributes: {
          autocapitalize: 'on'
        },
        customClass: {
          cancelButton: 'btn-cancel',
          confirmButton: 'btn-primary'
        }
      })

      if (res.isConfirmed) {
        try {
          tissGuide.observations = res.value
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
#tiss-guides-table {
  .table {
    th {
      vertical-align: middle;
      font-weight: bold;
      color: var(--dark-blue);
    }
    td {
      padding: 0.3rem 0.75rem;
      vertical-align: middle;
    }
    .check {

    }
    .items {
      text-align: left;
    }
    .situation {
      border-left: 1px solid var(--neutral-300);
      border-right: 1px solid var(--neutral-300);
    }
    .receive {
      padding-left: 0;
      .btn.btn-link {
        color: #8696AC;
        text-decoration: underline;
      }
    }
    .column-default {
      
    }
    td:last-of-type {
      width: 80px;
    }
  }
  .icon-eye {
    width: 24px;
    height: 24px;
    fill: var(--blue-500);
  }
  .icon {
    width: 24px;
    height: 24px;
    stroke: var(--blue-500);
    &.inactive {
      stroke: var(--neutral-300);
    }
  }
  .pointer {
    cursor: pointer;
  }
  .arrow {
    fill: var(--blue-500);
  }
  .checkmark {
    width: 25px;
    height: 25px;
  }
  .disabled-value {
    color: var(--type-placeholder);
  }
  .selected-guides {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    right: 0;
    justify-content: space-between;
    padding: 25px 115px 25px;
    font-size: 16px;
    background: white;
    box-shadow: 16px 0 22px -8px rgba(12, 29, 89, 0.2),
      4px 0 4px 0 rgba(12, 29, 89, 0.1);
    p {
      font-size: 16px;
      color: var(--dark-blue);
    }
    span {
      font-size: 16px;
      color: var(--blue-500);
    }
    button {
      font-size: 16px;
      color: #dc3545;
    }
  }
}
</style>
<style lang="scss">
.swal2-input-label {
  padding-left: 30px;
}

#tiss-guides-table {
  .situation {
    .btn-link {
      text-align: left;
    }
  }
}
</style>